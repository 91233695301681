import { OfferingDomain } from './offering-domain';
import { OfferingDisplayOptions } from '../display-options/offering-list-widget-display-options';
import {
  AlignmentOptions,
  DockLocationOptions,
  ImageAndTextRatioOptions,
  ImagePositionOptions,
  ImageResizeOptions,
  ImageShapeOptions,
  OfferingListLayoutOptions,
} from '../../Shared/appKeys/SettingsKeys';
import {
  PricingPlanInfo,
  ImageDto,
  OfferingType,
} from '@wix/bookings-uou-domain';
import { Experiments } from '../../Shared/experiments/experiments';

export interface IOfferingViewModel {
  title?: string;
  offeringInfoAriaTitle: string;
  tagLine?: string;
  duration?: string;
  price?: string;
  days?: string;
  startDate?: string;
  fullUrl: string;
  image?: ImageViewModel;
  action?: ActionViewModel;
  type?: OfferingType;
  pricingPlanInfo?: PricingPlanInfo;
  id: string;
  displayOnlyNoBookFlow: boolean;
  errorMessageTranslated?: string;
  dividerVisibility: boolean;
  textAlignment: AlignmentOptions;
  layout: OfferingListLayoutOptions;
  ratio: ImageAndTextRatioOptions;
  ratioFlipped: boolean;
  invertInfoPosition: boolean;
  verticalAlignInfoContent: boolean;
}

export interface ImageViewModel {
  mediaItem: ImageDto;
  isVisible: boolean;
  imageResize: ImageResizeOptions;
  focalPoint: { x: number; y: number };
  shape: ImageShapeOptions;
  aspectRatio: number;
}

export interface ActionViewModel {
  type;
  text: string;
  isVisible: boolean;
  isLarge: boolean;
  isSecondary: boolean;
}

export const DEFAULT_FOCAL_POINT = { x: 50, y: 50 };

export const MAX_TAGLINE_LENGTH = 70;

export const dockLocationToFocalPointMapper = {
  [DockLocationOptions.TOP]: { x: 50, y: 0 },
  [DockLocationOptions.TOP_RIGHT]: { x: 100, y: 0 },
  [DockLocationOptions.RIGHT]: { x: 100, y: 50 },
  [DockLocationOptions.BOTTOM_RIGHT]: { x: 100, y: 100 },
  [DockLocationOptions.BOTTOM]: { x: 50, y: 100 },
  [DockLocationOptions.BOTTOM_LEFT]: { x: 0, y: 100 },
  [DockLocationOptions.LEFT]: { x: 0, y: 50 },
  [DockLocationOptions.TOP_LEFT]: { x: 0, y: 0 },
  [DockLocationOptions.MIDDLE]: { x: 50, y: 50 },
};

export function getFocalPoint(
  dockLocation?: DockLocationOptions,
): { x: number; y: number } {
  return dockLocationToFocalPointMapper[dockLocation] || DEFAULT_FOCAL_POINT;
}

function getBookActionText(
  offeringDisplayOptions: OfferingDisplayOptions,
  formatter: Function,
) {
  return (
    offeringDisplayOptions.bookActionText ||
    formatter('info-card.design.button.book-it')
  );
}

function getNoBookActionText(
  offeringDisplayOptions: OfferingDisplayOptions,
  formatter: Function,
) {
  return (
    offeringDisplayOptions.noBookActionText ||
    formatter('info-card.design.button.more-info')
  );
}

function getImageShape(
  isMobile,
  offeringDisplayOptions,
  experiments: Experiments,
) {
  if (isMobile) {
    return offeringDisplayOptions.offeringImageShape;
  }
  return offeringDisplayOptions.layout === OfferingListLayoutOptions.GRID ||
    offeringDisplayOptions.layout === OfferingListLayoutOptions.STRIP
    ? offeringDisplayOptions.offeringImageShape
    : ImageShapeOptions.SQUARE;
}

function getImageResize(
  offeringDisplayOptions: OfferingDisplayOptions,
): ImageResizeOptions {
  return offeringDisplayOptions.layout ===
    OfferingListLayoutOptions.OVERLAPPING ||
    offeringDisplayOptions.layout === OfferingListLayoutOptions.STRIP ||
    (offeringDisplayOptions.layout === OfferingListLayoutOptions.GRID &&
      offeringDisplayOptions.offeringImageShape === ImageShapeOptions.ROUND)
    ? ImageResizeOptions.CROP
    : offeringDisplayOptions.offeringImageResize;
}

function getImageAspectRatio(
  isMobile: boolean,
  offeringDisplayOptions: OfferingDisplayOptions,
  experiments: Experiments,
): number {
  if (isMobile) {
    return offeringDisplayOptions.offeringImageShape ===
      ImageShapeOptions.SQUARE
      ? 1
      : 1.52;
  }

  if (offeringDisplayOptions.layout === OfferingListLayoutOptions.GRID) {
    if (
      offeringDisplayOptions.offeringImageShape === ImageShapeOptions.SQUARE
    ) {
      return 1;
    }

    if (
      offeringDisplayOptions.offeringImageShape ===
        ImageShapeOptions.RECTANGLE ||
      offeringDisplayOptions.offeringImageShape === ImageShapeOptions.ROUND
    ) {
      return 1.5;
    }
  }

  return null;
}

function getFormattedText(isDummy, text, formatter) {
  return isDummy ? formatter(text) : text;
}

function shortenText(text: string, max_length: number) {
  if (text.length > max_length) {
    return text.substring(0, max_length) + '...';
  }
  return text;
}

//todo - should be removed when yoshi will fix rtl!!!
function getAlignment(isRTL, alignment) {
  return !isRTL || alignment === AlignmentOptions.CENTER
    ? alignment
    : alignment === AlignmentOptions.LEFT
    ? AlignmentOptions.RIGHT
    : AlignmentOptions.LEFT;
}

function numOfItemsInMiddleColumn(
  offeringStartDateView,
  offeringDurationView,
  offeringPriceView,
  offeringDaysView,
): number {
  let numOfItems = 0;
  if (offeringStartDateView) {
    numOfItems++;
  }
  if (offeringDurationView) {
    numOfItems++;
  }
  if (offeringPriceView) {
    numOfItems++;
  }
  if (offeringDaysView) {
    numOfItems++;
  }
  return numOfItems;
}

export class OfferingViewModelFactory {
  static createOfferingViewModel(
    offeringDomain: OfferingDomain,
    offeringDisplayOptions: OfferingDisplayOptions,
    formatter: Function,
    isRTL: boolean,
    isMobile: boolean,
    experiments: Experiments,
  ): IOfferingViewModel {
    const offeringTitleView = getFormattedText(
      offeringDomain.dummy,
      offeringDomain.name,
      formatter,
    );
    const offeringDurationView =
      offeringDisplayOptions.isOfferingDurationVisible &&
      (offeringDomain.type === OfferingType.GROUP ||
        offeringDomain.type === OfferingType.INDIVIDUAL)
        ? offeringDomain.durationTextByFormat(formatter)
        : null;
    const offeringStartDateView =
      offeringDisplayOptions.isOfferingStartDateVisible &&
      offeringDomain.type === OfferingType.COURSE
        ? offeringDomain.durationTextByFormat(formatter)
        : null;
    const offeringPriceView = offeringDisplayOptions.isOfferingPriceVisible
      ? getFormattedText(
          offeringDomain.dummy,
          offeringDomain.priceText,
          formatter,
        )
      : null;
    const offeringDaysView =
      offeringDisplayOptions.isOfferingOfferedDaysVisible &&
      offeringDomain.type === OfferingType.GROUP
        ? offeringDomain.daysTextByFormat(formatter)
        : null;
    const offeringTagLineView =
      offeringDomain.tagLine && offeringDisplayOptions.isOfferingTagLineVisible
        ? shortenText(
            getFormattedText(
              offeringDomain.dummy,
              offeringDomain.tagLine,
              formatter,
            ),
            MAX_TAGLINE_LENGTH,
          )
        : null;
    const errorMessageTranslated = offeringDomain.errorMessageTranslation(
      formatter,
    );
    const offeringImageShape = getImageShape(
      isMobile,
      offeringDisplayOptions,
      experiments,
    );

    const offeringImageView = {
      mediaItem: offeringDomain.image,
      isVisible: offeringDisplayOptions.isOfferingImageVisible,
      imageResize: getImageResize(offeringDisplayOptions),
      focalPoint: getFocalPoint(
        offeringDisplayOptions.offeringImageDockLocation,
      ),
      shape: offeringImageShape,
      aspectRatio: getImageAspectRatio(
        isMobile,
        offeringDisplayOptions,
        experiments,
      ),
    };
    const offeringDividerView = offeringDisplayOptions.isOfferingDividerVisible;
    const offeringActionView: ActionViewModel = {
      isVisible: offeringDisplayOptions.isOfferingBookButtonVisible,
      type: offeringDisplayOptions.buttonStyle,
      text: offeringDomain.displayOnlyNoBookFlow
        ? getNoBookActionText(offeringDisplayOptions, formatter)
        : getBookActionText(offeringDisplayOptions, formatter),
      isLarge: offeringDisplayOptions.isLargeButton,
      isSecondary: offeringDisplayOptions.isSecondaryButton,
    };
    const offeringTextAlignment = getAlignment(
      isRTL,
      offeringDisplayOptions.offeringTextAlignment,
    ); //todo - should be removed when yoshi will fix rtl!!!
    const offeringLayout = isMobile
      ? OfferingListLayoutOptions.GRID
      : offeringDisplayOptions.layout;
    const offeringRatio = offeringDisplayOptions.ratio;
    const offeringRatioFlipped = offeringDisplayOptions.ratioFlipped;
    const invertInfoPosition = isRTL
      ? !(ImagePositionOptions.RIGHT === offeringDisplayOptions.imagePosition)
      : ImagePositionOptions.RIGHT === offeringDisplayOptions.imagePosition;
    const allDetailsHidden =
      !offeringTagLineView &&
      !offeringDividerView &&
      !offeringDaysView &&
      !offeringStartDateView &&
      !offeringDurationView &&
      !offeringPriceView;
    const stripUpToOneItemPerColumn =
      !offeringTagLineView &&
      numOfItemsInMiddleColumn(
        offeringStartDateView,
        offeringDurationView,
        offeringPriceView,
        offeringDaysView,
      ) < 2;

    return {
      title: offeringTitleView,
      offeringInfoAriaTitle: formatter('offering.info.aria-title'),
      tagLine: offeringTagLineView,
      fullUrl: offeringDomain.fullUrl,
      duration: offeringDurationView,
      price: offeringPriceView,
      days: offeringDaysView,
      startDate: offeringStartDateView,
      image: offeringImageView,
      action: offeringActionView,
      type: offeringDomain.type,
      pricingPlanInfo: offeringDomain.pricingPlanInfo,
      id: offeringDomain.id,
      displayOnlyNoBookFlow: offeringDomain.displayOnlyNoBookFlow,
      dividerVisibility: offeringDividerView,
      textAlignment: offeringTextAlignment,
      layout: offeringLayout,
      errorMessageTranslated,
      ratio: offeringRatio,
      ratioFlipped: offeringRatioFlipped,
      invertInfoPosition,
      verticalAlignInfoContent:
        offeringLayout === OfferingListLayoutOptions.STRIP
          ? stripUpToOneItemPerColumn
          : allDetailsHidden,
    };
  }
}
